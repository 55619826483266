<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import axios from 'axios'
export default {
  // computed: {
  //   key() {
  //     return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
  //   }
  // }
  // watch: {
  //   $route(to) {
  //     // 处理发包缓存问题
  //     this.contractCache();
  //   },
  // },
  methods: {
    //处理发包缓存问题
    // contractCache() {
    //   axios
    //     .get(`/config.json`, {
    //       params: {
    //         _t: new Date().getTime(),
    //       },
    //     })
    //     .then((res) => {
    //       console.log(777777)
    //       console.log(res)
    //       const VUE_APP_VERSION = res.data.version;
    //       const vers = window.localStorage.getItem("Version");

    //       // 如果缓存没有版本号，则是第一次打开，记录当前版本号并返回
    //       if (!vers) {
    //         window.localStorage.setItem("Version", VUE_APP_VERSION);
    //         return;
    //       }

    //       // 检查当前版本是否和缓存的版本不一致
    //       if (VUE_APP_VERSION != vers) {
    //         setTimeout(() => {
    //           alert(this.$t(`omission.newVersionDetectedPleaseRefresh`));

    //           // 更新版本号并刷新页面
    //           window.localStorage.setItem("Version", VUE_APP_VERSION);
    //           location.reload();
    //         }, 2000);
    //       }
    //     });
    // },
  },
};
</script>

<style lang="scss"></style>
