import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@views/Home.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/login'
	},
	{
		path: '/bxDeptMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/bxDeptMenu/bxDeptConf',
				name: '部门管理',
				component: () => import('@/views/bxDeptMenu/bxDeptConf.vue')
			},

		]
	},
	{
		path: '/bxDcUserMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/bxDcUserMenu/bxDcUserConf',
				name: '用户列表',
				component: () => import('@/views/bxDcUserMenu/bxDcUserConf.vue')
			},

		]
	},{
		path: '/bxRluoMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/bxRluoMenu/bxRluoConf',
				name: '权限管理',
				component: () => import('@/views/bxRluoMenu/bxRluoConf.vue')
			},

		]
	},{
		path: '/bxDcMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/bxDcMenu/bxDcConf',
				name: '菜品配置列表',
				component: () => import('@/views/bxDcMenu/bxDcConf.vue')
			},{
				path: '/bxDcMenu/bxDcMealsList',
				name: '菜品列表',
				component: () => import('@/views/bxDcMenu/bxDcMealsList.vue')
			},

		]
	},{
		path: '/bxDcOrderMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/bxDcOrderMenu/bxDcOrderConf',
				name: '订餐列表',
				component: () => import('@/views/bxDcOrderMenu/bxDcOrderConf.vue')
			},
			{
				path: '/bxDcOrderMenu/dcDcCheckList',
				name: '审核订餐列表',
				component: () => import('@/views/bxDcOrderMenu/dcDcCheckList.vue')
			},
			{
				path: '/bxDcOrderMenu/bxDcOrderList',
				name: '订单列表',
				component: () => import('@/views/bxDcOrderMenu/bxDcOrderList.vue')
			},
			{
				path: '/bxDcOrderMenu/bxDcQc',
				name: '用餐核销列表',
				component: () => import('@/views/bxDcOrderMenu/bxDcQc.vue')
			},

		]
	},

	{
		path: '/bxPxCatMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/bxPxCatMenu/dcPxCatList',
				name: '视频分类',
				component: () => import('@/views/bxPxCatMenu/dcPxCatList.vue')
			},
			,
			{
				path: '/bxPxCatMenu/addType',
				name: '添加分类',
				component: () => import('@/views/bxPxCatMenu/addType.vue')
			},

			{
				path: '/bxPxCatMenu/addVideo',
				name: '添加视频',
				component: () => import('@/views/bxPxCatMenu/addVideo.vue')
			}

		]
	},
	{
		path: '/bxPxVideoMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/bxPxVideoMenu/dcPxVideoList',
				name: '视频列表',
				component: () => import('@/views/bxPxVideoMenu/dcPxVideoList.vue')
			},
			{
				path: '/bxPxVideoMenu/dcPxVideodown',
				name: '下架视频',
				component: () => import('@/views/bxPxVideoMenu/dcPxVideodown.vue')
			}

		]
	},
	{
		path: '/bxPxNoticeMenu',
		name: '',
		component: Home,
		children: [
			{
				path: '/bxPxNoticeMenu/dcPxNoticeList',
				name: '公告列表',
				component: () => import('@/views/bxPxNoticeMenu/dcPxNoticeList.vue')
			},
			{
				path: '/bxPxNoticeMenu/addNotice',
				name: '添加视频',
				component: () => import('@/views/bxPxNoticeMenu/addNotice.vue')
			}

		]
	},
	



	{
		path: '/login',
		name: 'login',
		component: () => import('@views/login.vue')
	}
]

// 异步挂载的路由
// 动态需要根据权限加载的路由表
const asyncRouterMap = [
	{
		path: '/permission',
		component: Home,
		name: '权限测试',
		meta: { role: ['admin', 'super_editor'] }, // 页面需要的权限
		children: [
			{
				path: 'index',
				component: Home,
				name: '权限测试页',
				meta: { role: ['admin', 'super_editor'] } // 页面需要的权限
			}]
	},
	{ path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
	mode: 'history',
	routes,
	asyncRouterMap
})

console.log(router)

export default router
